<template>
    <div>
        <v-overlay :value="overlay" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-tabs v-model="tab" align-with-title dense grow>
            <v-tab>CREACIÓN DE RUTAS</v-tab>
            <v-tab>ADMINISTRACIÓN DE RUTAS</v-tab>
            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <v-card flat>
                        <v-col cols='12'>
                            <v-data-table :headers="headers" :items="dataTable" :loading="loadingTable" :search=search
                                show-select v-model=ordersSelected :key="`order_id`" loading-text="Cargando registros..."
                                item-key="order_id" class="elevation-1" dense small>
                                <template v-slot:item.data-table-select="{ item }">
                                    <v-checkbox 
                                        v-model="item.selected" 
                                        @click="selectedOrder(item)" 
                                        v-if="item.monto_pagado >= parseFloat(item.precio_cotizacion) ||
                                        item.precio_cotizacion == parseFloat(item.monto_pagado) + 0.01 ||
                                        item.precio_cotizacion == parseFloat(item.monto_pagado) + 0.02" />
                                    <span v-else style="color:red">Verifique el pago</span>
                                </template>
                                <template v-slot:top>
                                    <v-row align="center" justify="center">
                                        <v-col md=6>
                                            <v-text-field v-model=search label=Buscar />
                                        </v-col>
                                        <v-col md=3 class='text-center'>
                                            <v-btn small color="primary" @click="getCarrierAgent">
                                                <v-icon>mdi-plus</v-icon> Crear nueva ruta
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-slot:item.quote_id="{ item }">
                                    <v-btn text link :to="`/cotizacion/${item.quote_id}`">
                                        {{ item.quote_id }}
                                    </v-btn>
                                </template>
                                <template v-slot:item.total="{ item }">
                                    <span>$ {{ item.total }}</span>
                                </template>
                                <template v-slot:item.geo_point="{ item }">
                                    <v-btn color="success" icon @click="setDataGeoPoint(item)">
                                        <v-icon>mdi-map-marker-radius</v-icon>
                                    </v-btn>

                                </template>
                            </v-data-table>
                            <v-dialog max-width="800px" v-model=dialogGeoPoint style="background:white">
                                <v-card>
                                    <Map 
                                        :makers=maker 
                                        :saveClientUbication=false 
                                        :actionData=false 
                                        :searchInputs=false 
                                        @setPointShippment = "data => maker = [data]"
                                    />
                                    <v-btn block color="default" @click="dialogGeoPoint = false">
                                        <v-icon>mdi-cancel</v-icon> CERRAR
                                    </v-btn>
                                </v-card>
                            </v-dialog>
                            <v-dialog max-width="1000px" v-model=dialogCrearRoute style="background:white">
                                <v-card>
                                    <v-col>
                                        <v-form ref="form_route">
                                            <v-alert type="info" icon="mdi-routes" dense>
                                                CREAR NUEVA RUTA
                                            </v-alert>
                                            <v-simple-table dense>
                                                <template v-slot:default>
                                                    <thead>
                                                        <td colspan="3">
                                                            <v-row>
                                                                <v-col md=4>
                                                                    <v-autocomplete outlined dense v-model=carrier
                                                                        class="mt-1" label="Seleccione al transportista"
                                                                        :items=carriers item-value="party_id"
                                                                        item-text="name" :disabled="existRoute != null"
                                                                        prepend-inner-icon="mdi-truck-fast"
                                                                        :rules="requiredFormRoute"></v-autocomplete>
                                                                </v-col>
                                                                <v-col md=4>
                                                                    <v-autocomplete outlined dense v-model=vehicle
                                                                        class="mt-1" label="Seleccione el vehículo"
                                                                        :items=vehicles :disabled="existRoute != null"
                                                                        item-value="vehiculo_id" item-text="descripcion"
                                                                        prepend-inner-icon="mdi-truck-fast"
                                                                        :rules="requiredFormRoute">
                                                                        <template v-slot:item="{ item }">
                                                                            {{ item.descripcion }} - {{ item.placa }}
                                                                        </template>
                                                                        <template v-slot:selection="{ item }">
                                                                            {{ item.descripcion }} - {{ item.placa }}
                                                                        </template>
                                                                    </v-autocomplete>
                                                                </v-col>
                                                                <v-col md=4>
                                                                    <v-autocomplete outlined dense v-model=existRoute
                                                                        class="mt-1" clearable
                                                                        label="Seleccione ruta existente"
                                                                        :items=dataTableAdminRoutes item-value="ruta_id"
                                                                        item-text="name">
                                                                    </v-autocomplete>
                                                                </v-col>
                                                            </v-row>
                                                        </td>
                                                    </thead>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left">Cotización</th>
                                                            <th class="text-left">Dirección</th>
                                                            <th class="text-left">Eliminar</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(order, x) in ordersSelected" :key="x">
                                                            <td>{{ order.quote_id }}</td>
                                                            <td>
                                                                {{ order.direccion }}
                                                            </td>
                                                            <td class="text-center">
                                                                <v-icon @click="removeOrder(order)"
                                                                    color="red">mdi-delete-forever</v-icon>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-form>
                                    </v-col>
                                    <v-card-actions>
                                        <v-col class='text-center'>
                                            <v-btn color="primary" @click="createRoute">
                                                <v-icon>mdi-content-save</v-icon> Guardar
                                            </v-btn>
                                            <v-btn @click="closeDialogCrearRoute">
                                                <v-icon>mdi-cancel</v-icon> Cancelar
                                            </v-btn>
                                        </v-col>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card flat>
                        <v-col cols='12'>
                            <v-data-table 
                                :headers="headersAdminRoutes" 
                                :items="dataTableAdminRoutes"
                                :loading="loadingTable" 
                                single-expand 
                                show-expand 
                                :expanded.sync="expanded"
                                :search=searchAdminRoutes 
                                key="ruta_id" 
                                loading-text="Cargando registros..."
                                item-key="ruta_id" class="elevation-1" dense small
                            >
                                <template v-slot:top>
                                    <v-row align="center" justify="center">
                                        <v-col md=6>
                                            <v-text-field v-model=search label=Buscar />
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-slot:item.packages="{ item }">
                                    <v-text-field v-model="item.value_scan" class="mt-0 input-quotation" style="height:25px"
                                        dense @keyup="deliverPackage(item)" @click="expandRow(item)"></v-text-field>
                                    <!-- <div v-else style="background:green;color:white" class="text-center">
                                    <b>EN RUTA</b>
                                </div> -->
                                </template>
                                <template v-slot:expanded-item="{ headers, item }">
                                    <td :colspan="headers.length">
                                        <v-alert 
                                            dense 
                                            type="info" 
                                            icon="mdi-package-variant-closed" 
                                            class="mt-6"
                                        >
                                            <b> PAQUETES PARA ENTREGAR A {{ item.name.toUpperCase() }} </b>
                                        </v-alert>
                                        <v-simple-table
                                            dense 
                                            class="mb-6"
                                        >
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-left">Cotización</th>
                                                        <th class="text-left">Pedido</th>
                                                        <th class="text-left">Cliente</th>
                                                        <th class="text-left">Dirección</th>
                                                        <th class="text-left">Peso/Unidad</th>
                                                        <th class="text-left">Estado</th>
                                                        <th class="text-center" style="width:150px">Acción</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr 
                                                        v-for="trans in item.transacciones" 
                                                        :key="trans.id"
                                                    >
                                                        <td>
                                                            <v-btn
                                                                color="default" 
                                                                small 
                                                                link 
                                                                target="_blank" 
                                                                text
                                                                :to="`/cotizacion/${trans.quote_id}`"
                                                            >
                                                                {{ trans.quote_id }}
                                                            </v-btn>
                                                        </td>
                                                        <td>{{ trans.order_id }}</td>
                                                        <td>{{ trans.cliente }}</td>
                                                        <td>{{ trans.direccion }}</td>
                                                        <td>
                                                            <v-text-field 
                                                                v-model.number="trans.peso_paquete"
                                                                class="mt-0 input-quotation" 
                                                                style="height:25px"
                                                                dense
                                                                type="number"
                                                                min="0"
                                                                @keyup="()=>{
                                                                    trans.peso_paquete < 0 ? trans.peso_paquete = 0 : trans.peso_paquete;
                                                                    guardarPesoPaquete(trans)
                                                                }"
                                                            >
                                                                <template v-slot:append>
                                                                    <v-select
                                                                        v-model="trans.unidad_medida"
                                                                        :items="unidadesMedidas"
                                                                        style="height:25px;margin-left: 20px;"
                                                                        item-value="id"
                                                                        item-text="name"
                                                                        dense
                                                                        hide-details
                                                                    />
                                                                </template>
                                                            </v-text-field>
                                                        </td>
                                                        <td>
                                                            {{ (trans.entregado && !trans.verificado) ? 'Entregado, no facturado' : ( (!trans.entregado && trans.verificado) ? 'Facturado, no entregado' : (trans.estado_entrega == 'GE_DEVUELTO' ? 'DEVUELTO' : trans.verificado ? 'Entregado' :'No entregado') ) }}
                                                        </td>
                                                        <td class="text-center">
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-icon
                                                                        :color="(trans.entregado && !trans.verificado) || trans.estado_entrega == 'GE_DEVUELTO' ? 'warning' : (trans.verificado ? 'success' : 'red')"
                                                                        v-bind="attrs" v-on="on" class="mr-3">
                                                                        mdi-{{ trans.estado_entrega == 'GE_DEVUELTO' ? 'alert-decagram' : (trans.verificado ||  trans.entregado ? 'check-all' : 'close-circle-outline') }}
                                                                    </v-icon>
                                                                </template>
                                                                <span>
                                                                    Paquete {{ trans.estado_entrega == 'GE_DEVUELTO' ? 'devuelto' : (trans.verificado || trans.entregado ?'entregado' : 'no entregado') }}
                                                                </span>
                                                            </v-tooltip>
                                                            <v-tooltip bottom
                                                                v-if="trans.estado_entrega == 'GE_CREADO' || trans.estado_entrega == 'GE_IMPRESO' || trans.estado_entrega == 'GE_ENTREGADO'"
                                                            >
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-icon color="red" v-bind="attrs" v-on="on"
                                                                        @click="deleteOrderRoute(trans)">mdi-delete-forever</v-icon>
                                                                </template>
                                                                <span>Eliminar paquete de la ruta</span>
                                                            </v-tooltip>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </td>
                                </template>
                                <template v-slot:item.ruta="{ item }">
                                    <v-tooltip bottom
                                        v-if="!item.transacciones.filter(e => e.estado_entrega == 'GE_DEVUELTO').length">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon class="mx-4" color="success" v-bind="attrs" v-on="on"
                                                @click="imprimirRuta(item)">
                                                mdi-printer
                                            </v-icon>

                                        </template>
                                        <span>Imprimir ruta </span>
                                    </v-tooltip>

                                    <v-tooltip bottom
                                        v-if="!item.transacciones.filter(e => e.estado_entrega == 'GE_DEVUELTO').length">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon 
                                                color="success" 
                                                v-bind="attrs" 
                                                v-on="on" 
                                                @click="sendRoute(item)"
                                            >
                                                mdi-truck-delivery
                                            </v-icon>
                                        </template>
                                        <span>Enviar ruta </span>
                                    </v-tooltip>

                                    <v-chip v-if="item.transacciones.filter(e => e.estado_entrega == 'GE_DEVUELTO').length"
                                        class="ma-2 warning" color="white">
                                        {{ item.transacciones.filter(e => e.estado_entrega == 'GE_DEVUELTO').length }}
                                        Pedidos
                                        devueltos <v-icon>mdi-sync-alert</v-icon>
                                    </v-chip>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-tabs>
        <iframe name="framePrint" style="width:0px;height:0px;visibility:hidden"></iframe>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import Vue from 'vue'
import Map from '../general/Map'

var intervalBarCode = ""
var intervalPesoPaquete = ""

export default {
    name: 'ListaRutasComponent',
    components: {
        Map
    },
    data: () => ({
        headers: [
            { text: 'Cotización', align: 'start', value: 'quote_id' },
            { text: 'Cliente', align: 'start', value: 'cliente', },
            { text: 'Dirección', align: 'start', value: 'direccion' },
            { text: 'Envio antes de', align: 'start', value: 'envio_antes_de', width: 120, },
            { text: 'Envio despues de', align: 'start', value: 'envio_despues_de', width: 140, },
            { text: 'Tipo de envío', align: 'start', value: 'envio' },
            { text: 'Geo referencia', align: 'center', value: 'geo_point' }
        ],
        headersAdminRoutes: [
            { text: 'ID ruta', align: 'start', value: 'ruta_id' },
            { text: 'Fecha', align: 'start', value: 'fecha' },
            { text: 'Transportista', align: 'start', value: 'name', },
            { text: 'Vehiculo', align: 'start', value: 'vehicle' },
            { text: 'Escanear paquetes', align: 'start', value: 'packages' },
            { text: 'Ver paquetes', value: 'data-table-expand', align: 'center' },
            { text: 'Enviar ruta', value: 'ruta', align: 'center' }
        ],
        dataTableAdminRoutes: [],
        required: [v => !!v || 'El campo es requerido'],
        dataTable: [],
        expanded: [],
        ordersSelected: [],
        carriers: [],
        vehicles: [],
        unidadesMedidas: [
            {id:'kg', name:'kg'},
            {id:'g', name:'g'}
        ],
        options: {},
        maker: [{ latLng: [0, 0] }],
        tab: null,
        search: null,
        carrier: null,
        vehicle: null,
        barCodePackage: null,
        searchAdminRoutes: null,
        selectedRoute: null,
        existRoute: null,
        totalItems: 1,
        currentPage: 1,
        itemsPerPage: 20,
        loadingTable: false,
        dialogGeoPoint: false,
        dialogCrearRoute: false
    }),
    computed: {

        ...mapState('master', ['user', 'tenantId', 'overlay']),

        requiredFormRoute() {
            return [
                (v) => {

                    if (this.existRoute == null && (this.carrier == null || this.vehicle == null)) {
                        return 'El campo es obligatorio'
                    } else {
                        return true
                    }

                }
            ]
        },

    },
    watch: {

        existRoute: function (val) {
            if (val != null) {
                this.carrier = null
                this.vehicle = null
            }
        }

    },
    methods: {

        ...mapMutations('master', ['setUrl', 'setOverlay']),

        ...mapActions('master', ['requestApi', 'alertNotification']),

        ...mapActions('pos', ['dataPrinter']),

        getDeliveryOrders() {

            this.setUrl('pedidos-por-entregar')
            this.requestApi({
                method: 'GET',
                data: {}
            }).then(res => {
                console.log(res)
                this.dataTable = res.data._embedded.pedidos_por_entregar[0]
                this.dataTableAdminRoutes = res.data._embedded.pedidos_por_entregar[1].map(item => {

                    item.value_scan = ''

                    item.transacciones.map(trans =>{

                        if(['',null].includes(trans.unidad_medida))
                            trans.unidad_medida = 'kg'

                        return trans

                    })

                    return item

                })

                console.log('this.dataTableAdminRoutes',this.dataTableAdminRoutes)

            })

        },

        getCarrierAgent() {
            console.log(this.ordersSelected)
            if (!this.ordersSelected.length) {
                Vue.swal({
                    html: 'Debe seleccionar al menos un pedido',
                    icon: 'warning'
                })
                return
            }

            this.setUrl('pedidos-por-entregar/0')
            this.requestApi({
                method: 'GET',
                data: {}
            }).then(res => {
                console.log(res.data.carriers)
                this.carriers = res.data.carriers
                this.vehicles = res.data.vehicles
                this.vehicle = null
                this.carrier = null
                this.existRoute = null
                this.dialogCrearRoute = true

            })

        },

        createRoute() {

            if (!this.$refs.form_route.validate())
                return

            this.setUrl('pedidos-por-entregar')
            this.requestApi({
                method: 'POST',
                data: {
                    typeStore: 'storeRoute',
                    ordersSelected: this.ordersSelected,
                    vehicle: this.vehicle,
                    carrier: this.carrier,
                    existRoute: this.existRoute
                }
            }).then(res => {

                this.getDeliveryOrders()
                this.dialogCrearRoute = false
                this.alertNotification({
                    param: {
                        html: res.data.res.msg
                    }
                })
                this.ordersSelected.forEach(item => { item.selected = false })
                this.ordersSelected = []
            })

        },

        setDataGeoPoint(item) {
            this.dialogGeoPoint = true
            this.maker = [{
                latLng: [
                    item.latitude != null ? item.latitude : 0,
                    item.longitude != null ? item.longitude : 0
                ],
                label: item.latitude != null ? item.direccion : 'Sin geo posición asignada',
            }]
        },

        closeDialogCrearRoute() {
            this.dialogCrearRoute = false
            this.ordersSelected.forEach(item => { item.selected = false })
            this.ordersSelected = []
        },

        removeOrder(item) {
            let index = this.ordersSelected.indexOf(item)
            this.ordersSelected[index].selected = false
            this.ordersSelected.splice(index, 1)
        },

        deleteOrderRoute(order) {

            let obj = this.dataTableAdminRoutes.find(obj => obj.ruta_id == order.ruta_id)

            if (typeof obj != 'undefined' && obj.transacciones.length == 1) {

                Vue.swal({
                    html: "Si elimina esta pedido de la ruta se eliminará la ruta completa, está seguro?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cerrar',
                    ...this.paramAlertQuestion
                }).then(result => {

                    if (result.isConfirmed)
                        this.actionDeleteOrderRoute(order, true)

                })

            } else {

                this.actionDeleteOrderRoute(order)

            }

        },

        actionDeleteOrderRoute(order, deleteAll) {

            this.setUrl('pedidos-por-entregar')
            this.requestApi({
                method: 'POST',
                data: {
                    typeStore: 'deleteteOrderRoute',
                    rutaTransaccionId: order.id,
                    routeId: order.ruta_id,
                    orderId: order.order_id,
                    deleteAll
                }
            }).then(res => {
                console.log(res)
                this.getDeliveryOrders()
                this.alertNotification({
                    param: {
                        html: res.data.msg
                    }
                })
            })

        },

        expandRow(item) {

            this.expanded = []
            this.expanded.push(item)

        },

        deliverPackage(route) {

            clearInterval(intervalBarCode)

            if (route.value_scan.trim() != '') {

                intervalBarCode = setTimeout(() => {

                    let objRoute = this.dataTableAdminRoutes.find(r => r.ruta_id == route.ruta_id)
                    let objTrans = route.transacciones.find(e => e.quote_id == route.value_scan.trim().toUpperCase())

                    if (objTrans.verificado && objTrans.estado_entrega != 'GE_DEVUELTO' && objTrans.entregado) {

                        route.value_scan = ''
                        Vue.swal({
                            html: 'El paquete ya fue facturado y entregado',
                            icon: 'warning',
                            ...this.paramAlertQuestion
                        })
                        return false

                    } else if (objTrans.verificado && objTrans.estado_entrega != 'GE_DEVUELTO' && !objTrans.entregado) {

                        this.setOverlay(true)
                        this.setUrl('estado-cotizacion')
                        this.requestApi({
                            method: 'POST',
                            data: {
                                typeStore: 'deliverPackage',
                                orderId: objTrans.order_id,
                                quoteId: objTrans.quote_id,
                            }
                        }).then(res => {

                            try {
                                if (res.data.res.success) {

                                    let indexTrans = route.transacciones.indexOf(objTrans)

                                    route.transacciones[indexTrans].entregado = true

                                    this.dataPrinter({
                                        product_store_id: res.data.res.quote.product_store_id,
                                        printers: res.data.res.printers,
                                        shipment_method_type_id: res.data.res.quote.shipment_method_type_id
                                    }).then(res => {

                                        //window.frames['framePrint'].location.replace(`https://${res.ipPrinter}/impresion/escpos-php/recibos/orden.php?orderId=${objTrans.order_id}&quoteId=${objTrans.quote_id}&userId=${this.user.user_ligin_id}&tenantId=${this.tenantId}&impresora=${res.printer}&puerto_impresora=${res.portPrinter}`)
                                        this.setUrl('impresion')
                                        this.requestApi({
                                            method: "POST",
                                            data: {
                                                // typeStore: "reprintCommand",
                                                quoteId: objTrans.quote_id,
                                                orderId: objTrans.order_id,

                                                userId: this.user.user_ligin_id,
                                                tenantId: this.tenantId,
                                                impresora: res.printer,
                                                puerto_impresora: res.portPrinter,
                                                reimpreso: false,
                                                formato: "orden"
                                            },
                                        }).then(() => {
                                            // this.setOverlay(false);
                                        }).catch(() => {
                                            //this.setOverlay(false);
                                        });

                                    })

                                    this.alertNotification({
                                        param: {
                                            html: res.data.res.msg
                                        }
                                    })

                                }
                            } catch (error) {
                                console.log(error)
                            }
                            console.log(res)


                            this.setOverlay(false)

                        }).catch(() => {
                            this.setOverlay(false)
                        })

                    } else if (objTrans.estado_entrega == 'GE_DEVUELTO') {

                        let action = null
                        Vue.swal({
                            ...this.paramAlertQuestion,
                            html: 'Seleccione la acción que desea realizar',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonText: 'Aceptar',
                            cancelButtonText: 'Cerrar',
                            input: 'select',
                            inputOptions: {
                                rescheduleQuote: 'Reprogramar',
                                creditNoteQuote: 'Devolución'
                            },
                            inputPlaceholder: 'Seleccione la acción',
                            inputValidator: value => {
                                return new Promise((resolve) => {
                                    if (value == '') {
                                        resolve('Debe seleccionar la acción')
                                    } else {
                                        resolve()
                                        action = value
                                    }
                                })
                            }
                        }).then(result => {

                            if (result.isConfirmed) {

                                this.setOverlay(true)
                                this.setUrl('estado-cotizacion')
                                this.requestApi({
                                    method: 'POST',
                                    data: {
                                        typeStore: action,
                                        rutaTransaccionId: objTrans.ruta_transaccion_id,
                                        orderId: objTrans.order_id
                                    }
                                }).then(res => {
                                    console.log(res)
                                    this.alertNotification({
                                        param: {
                                            html: res.data.msg
                                        }
                                    })
                                    this.setOverlay(false)
                                    this.getDeliveryOrders()

                                }).catch(() => { })
                                    .then(() => {
                                        this.setOverlay(false)
                                    })

                            }

                        })

                    } else if (typeof objTrans == 'undefined') {

                        Vue.swal({
                            html: 'El paquete no pertenece al transportista',
                            icon: 'warning',
                            ...this.paramAlertQuestion
                        })

                    } else {

                        this.setOverlay(true)
                        this.setUrl('estado-cotizacion')
                        this.requestApi({
                            method: 'POST',
                            data: {
                                typeStore: 'dispatchQuoteWithShipping',
                                orderId: objTrans.order_id,
                                quoteId: objTrans.quote_id,
                                sinVerificar: route.transacciones.filter(obj => !obj.verificado).length,
                                rutaId: objRoute.ruta_id
                            }
                        }).then(res => {

                            if (res.data.res.success) {

                                let indexTrans = route.transacciones.indexOf(objTrans)
                                if (res.data.res.accion)
                                    route.transacciones[indexTrans].verificado = true

                                route.transacciones[indexTrans].entregado = true

                                this.dataPrinter({
                                    product_store_id: res.data.res.quote.product_store_id,
                                    printers: res.data.res.printers,
                                    shipment_method_type_id: res.data.res.quote.shipment_method_type_id
                                }).then(res => {
                                    //window.frames['framePrint'].location.replace(`https://${res.ipPrinter}/impresion/escpos-php/recibos/orden.php?orderId=${objTrans.order_id}&quoteId=${objTrans.quote_id}&userId=${this.user.user_ligin_id}&tenantId=${this.tenantId}&impresora=${res.printer}&puerto_impresora=${res.portPrinter}`)
                                    this.setUrl('impresion')
                                    this.requestApi({
                                        method: "POST",
                                        data: {
                                            // typeStore: "reprintCommand",
                                            quoteId: objTrans.quote_id,
                                            orderId: objTrans.order_id,

                                            userId: this.user.user_ligin_id,
                                            tenantId: this.tenantId,
                                            impresora: res.printer,
                                            puerto_impresora: res.portPrinter,
                                            reimpreso: false,
                                            formato: "orden"
                                        },
                                    }).then(() => {
                                        // this.setOverlay(false);
                                    }).catch(() => {
                                        //this.setOverlay(false);
                                    });
                                })

                                this.alertNotification({
                                    param: {
                                        html: res.data.res.msg
                                    }
                                })

                            }

                            this.setOverlay(false)

                        }).catch(() => {
                            this.setOverlay(false)
                        })

                    }

                    if (typeof objRoute != 'undefined') {
                        let index = this.dataTableAdminRoutes.indexOf(objRoute)
                        // this.dataTableAdminRoutes[index].value_scan=''
                    }

                }, 200)

            }

        },

        selectedOrder(item) {

            let index = this.ordersSelected.indexOf(item)
            console.log(index)
            if (index < 0) {
                this.ordersSelected.push(item)
            } else {
                this.ordersSelected.splice(index, 1)
            }
        },

        sendRoute(item) {
            console.log(item)
            Vue.swal({
                html: "Esta seguro de enviar esta ruta?<br /> Esta acción no se puede revertir",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cerrar',
                ...this.paramAlertQuestion
            }).then(result => {
                if (result.isConfirmed) {
                    this.setOverlay(true)
                    this.setUrl('estado-cotizacion')
                    this.requestApi({
                        method: 'POST',
                        data: {
                            typeStore: 'sendRoute',
                            rutaId: item.ruta_id,
                        }
                    }).then(res => {
                        this.getDeliveryOrders()
                        this.alertNotification({
                            param: {
                                html: res.data.res.msg
                            }
                        })
                        this.setOverlay(false)
                    }).catch(() => {
                        this.setOverlay(false)
                    })
                }
            })
        },

        imprimirRuta(item) {
            //console.log(item)
            this.setOverlay(true)
            this.setUrl('rutas')
            this.requestApi({
                method: 'POST',
                data: {
                    tipoAccion: 'imprimirRuta',
                    rutaId: item.ruta_id,
                }
            }).then(res => {
                var a = document.createElement("a");
                a.href =
                    "data:application/pdf;base64," +
                    res.data.documento.datos
                        .trim()
                        .replace('"', "")
                        .replace(/(\r\n|\n|\r)/gm, "");
                a.download = res.data.nombreArchivo + ".pdf";
                a.click();
                this.setOverlay(false)
            }).catch(() => {
                this.setOverlay(false)
            });
        },

        guardarPesoPaquete(trans) {
          
            clearInterval(intervalPesoPaquete)

            if(trans.peso_paquete != ''){   

                intervalPesoPaquete = setTimeout(() => {

                    this.setUrl('estado-cotizacion')
                    this.requestApi({
                        method: 'POST',
                        data: {
                            typeStore: 'guardarPesoPaquete',
                            rutaTransaccionId: trans.id,
                            peso: trans.peso_paquete,
                            unidad: trans.unidad_medida
                        }
                    }).then(res => {
            
                        this.alertNotification({
                            param: {
                                html: res.data.res.msg,
                                timer: 130000
                            }
                        })
                    
                    }).catch(err => {
                        console.log(err)
                    })

                }, 500)

            }

        }

    },
    mounted() {

        this.getDeliveryOrders()
    }
}
</script>

<style >
.swal2-select {
    border: 1px solid;

}
</style>
